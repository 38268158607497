import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link } from "react-router-dom";

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const BackLinkContainer = styled(Link)`
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  backdrop-filter: blur(1em);
  border: 2px solid;
  border-color: currentColor;
  border-radius: 100em !important;
  color: ${colors.foreground};
  display: flex;
  padding: 1em;
  position: fixed;
  bottom: 4.6em;
  left: 4.6em;
  z-index: 2;

  ${queryBelow("bigPhone", css`
    bottom: 7.5%;
    left: 7.5%;
  `)}

  svg {
    height: 1.4em;
    width: 1.4em;

    &, use {
      fill: currentColor;
    }
  }

  &:hover {
    border-color: ${colors.foreground};
    color: ${colors.background};
    background-color: rgba(255,255,255,1);
  }
`

// BackLink
class BackLink extends Component {
  render() {
    const { url } = this.props;

    return(
      <BackLinkContainer to={url}>
        <svg>
          <use xlinkHref="#arrow-left" />
        </svg>
      </BackLinkContainer>
    );
  }
}

export default BackLink;
