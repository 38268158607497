import React, { Component } from 'react'
import styled, { css } from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
// import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
import Logo from '../../components/Logo'

// Styled Components
const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 3em 0;
  position: relative;
  width: 100%;

  &.fixed {
    position: fixed;
    padding: 1.4em 0;
    background-color: rgba(40,40,40,0.65);
    backdrop-filter: blur(1em);
    z-index: 2;
    top: 0;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }

  &:before {
    background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    ${queryBelow("bigPhone", css`
      height: 14em;
    `)}
  }
`

// Header
class Header extends Component {
  render() {
    // const { number, title } = this.props;

    return(
      <HeaderContainer>
        <div className="wrap">
          <Logo />
        </div>
      </HeaderContainer>
    );
  }
}

export default Header;
