import React, { Component } from 'react'
import styled from 'styled-components'

const MenuItemTitleContainer = styled.h2`
  display: flex;
  justify-content: space-between;
  text-transform: lowercase;
  width: 100%;
`

class MenuItemTitle extends Component {
  render() {
    const {
      title,
      price
    } = this.props;

    return(
      <MenuItemTitleContainer>
        <span>{title}</span>
        <span className='menu-item-price'>{price}</span>
      </MenuItemTitleContainer>
    );
  }
}

export default MenuItemTitle;
