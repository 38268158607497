export const bowlsProtein = [

  {
    "title": "grilled chicken",
    "nutrition": [
      { "value": "187", "unit": "Calories" },
      { "value": "26g", "unit": "Protein" },
      { "value": "0g", "unit": "Total Carbohydrate" },
      { "value": "9g", "unit": "Total Fat" },
    ]
  },
  {
    "title": "wok-fired steak",
    "priceModifier": "+$2.00",
    "nutrition": [
      { "value": "200", "unit": "Calories" },
      { "value": "22g", "unit": "Protein" },
      { "value": "0g", "unit": "Total Carbohydrate" },
      { "value": "12g", "unit": "Total Fat" },
    ]
  },
  {
    "title": "lentils",
    "nutrition": [
      { "value": "220", "unit": "Calories" },
      { "value": "9g", "unit": "Protein" },
      { "value": "22g", "unit": "Total Carbohydrate" },
      { "value": "11g", "unit": "Total Fat" },
    ]
  },
  // {
  //   "title": "shrimp",
  //   "priceModifier": "+$2.50",
  //   "nutrition": [
  //     { "value": "110", "unit": "Calories" },
  //     { "value": "12g", "unit": "Protein" },
  //     { "value": "1g", "unit": "Total Carbohydrate" },
  //     { "value": "6g", "unit": "Total Fat" },
  //     { "value": "Note", "unit": "*Please be aware that Shrimp is a “Shellfish”, so it is not an option for customers with shellfish allergies." },
  //   ]
  // },
  {
    "title": "tofu",
    "nutrition": [
      { "value": "240", "unit": "Calories" },
      { "value": "8g", "unit": "Protein" },
      { "value": "2g", "unit": "Total Carbohydrate" },
      { "value": "22g", "unit": "Total Fat" },
    ]
  },

]
