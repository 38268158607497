import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
// import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import StepTitle from '../../components/StepTitle'
import NavItem from '../../components/NavItem'

// Styled Components
const HomeContainer = styled.section`
  z-index: 9;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`

const MenuTitle = styled(StepTitle)`
  &, span {
    color: white;
    justify-content: flex-start;
  }

  &:after {
    background-color: rgba(255,255,255,0.1);
  }
`

// Home
class Home extends Component {
  render() {
    return(
      <>
        <Helmet>
          <title>Menu | Balance Pan-Asian Grille</title>
          <meta name="description" content="A hip spot for clean Asian food. Serving up snacks, build-a-bowls, tacos, teas, and seasonal specials." />
        </Helmet>
        <HomeContainer>
          <div className="wrap">
            <MenuTitle
              title="Choose your location"
            />
            <Nav>
              <NavItem url="/cleveland/menu">cleveland</NavItem>
              {/* <NavItem url="/dallas/menu">dallas</NavItem>
              <NavItem url="/denver/menu">denver</NavItem> */}
              <NavItem url="/menu">toledo</NavItem>
            </Nav>
          </div>
        </HomeContainer>
      </>
    );
  }
}

export default Home;
