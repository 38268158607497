import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

const SimpleCalloutContainer = styled.div`
  display: inline-block;
  margin-bottom: 2em;
  padding: 0.6em 2em;
  position: relative;

  ${queryBelow("bigPhone", css`
    font-size: 0.875em;
    padding: 0.6em 5%;
  `)}

  ${queryBelow("smallerPhone", css`
    font-size: 0.8em;
  `)}
`

const SimpleCalloutText = styled.span`
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${colors.foreground};
  align-items: center;
  background-image: linear-gradient(${colors.foreground}, ${colors.foreground});
  color: ${colors.foreground};
  display: inline-flex;
  font-family: "effrabold";
  font-size: 0.875em;
  justify-content: center;
  letter-spacing: 0.2em;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    background-image: inherit;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -2;
  }

  &:after {
    background-color: #000;
    bottom: 2px;
    left: 2px;
    right: 2px;
    top: 2px;
    z-index: -1;
  }

`

class SimpleCallout extends Component {
  render() {
    const {
      className,
      calloutText
    } = this.props;

    return(
      <SimpleCalloutContainer className={className}>
        <SimpleCalloutText className={className}>
          {calloutText}
        </SimpleCalloutText>
      </SimpleCalloutContainer>
    );
  }
}

export default SimpleCallout;
