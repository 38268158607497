import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
// import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

const MenuItemPhotoContainer = styled.div`
  background-image: ${props => `url(${props.menuItemPhoto})`};
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 10em;
  margin-bottom: 1.4rem;
  margin-right: 2rem;
  position: relative;
  width: 240px;
  z-index: 1;

  ${queryBelow("phone", css`
    background-position: center center;
    margin-bottom: 1em;
    margin-right: 0;
    width: 100%;
    height: 27vh;
  `)}
`

class MenuItemPhoto extends Component {
  render() {
    const { image } = this.props;

    return(
      <MenuItemPhotoContainer menuItemPhoto={image} />
    );
  }
}

export default MenuItemPhoto;
