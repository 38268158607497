import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

// Data
import { snacks } from "../../data/snacks-cleveland";

// Components
import BackLink from '../../components/BackLink'
import SubHeader from '../../components/SubHeader'
import MenuSection from '../../components/MenuSection'
import MenuItems from '../../components/MenuItems'
import MenuItem from '../../components/MenuItem'
import StepTitle from '../../components/StepTitle'

// Snacks
class Snacks extends Component {
  render() {
    return(
      <>
        <Helmet>
          <title>Snacks - Cleveland Menu | Balance Pan-Asian Grille</title>
          <meta name="description" content="Snacks and salads to complete your meal — or to satisfy a craving. Go nutritious or decadent! Vegan and gluten-free options." />
        </Helmet>
        <MenuSection>
          <div className="wrap">
            <StepTitle
              title="Cleveland"
            />
          </div>
          <SubHeader
            title="snacks"
            description="Choose a tasty snack as a starter or to share with friends. <em>Nutrition listed accounts for item including sauce (if applicable)</em>."
          />
          <MenuItems>
            {
              snacks.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
          </MenuItems>
        </MenuSection>
        <BackLink url="/cleveland/menu"/>
      </>
    );
  }
}

export default Snacks;
