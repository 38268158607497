import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
// import styled, { css } from 'styled-components'

// Utilities
// import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Data
import { bowls } from "../../data/bowls-cleveland";

// Components
import BackLink from '../../components/BackLink'
import SubHeader from '../../components/SubHeader'
import MenuSection from '../../components/MenuSection'
import MenuItems from '../../components/MenuItems'
import MenuItem from '../../components/MenuItem'
import BowlsInstructions from '../../components/BowlsInstructionsCleveland'
import StepTitle from '../../components/StepTitle'

// Bowls
class Bowls extends Component {
  render() {
    return(
      <>
        <Helmet>
          <title>Bowls - Cleveland Menu | Balance Pan-Asian Grille</title>
          <meta name="description" content="Build your own bowl by choosing the flavor, then adding a protein and starch choice! Vegan and gluten-free options." />
        </Helmet>
        <MenuSection>
          <div className="wrap">
            <StepTitle
              title="Cleveland"
            />
          </div>
          <SubHeader title="bowls" />
          <MenuItems>
            {
              bowls.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    additionalDescription={menuItem.additionalDescription}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
          </MenuItems>
        </MenuSection>
        <BowlsInstructions />
        <BackLink url="/cleveland/menu"/>
      </>
    );
  }
}

export default Bowls;
