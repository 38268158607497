import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { queryBelow } from "../../utilities/mediaQuery"

// Components
import NutritionItem from '../../components/NutritionItem'

const ColumnItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  width: calc((100% / 2) - 2em);

  ${queryBelow("bigPhone", css`
    width: 100%;
  `)}
`

const Heading = styled.h3`
  margin-bottom: 0.4rem;
`

const NutritionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

class ColumnItem extends Component {
  render() {
    const { title, priceModifier, nutrition } = this.props;

    return(
      <ColumnItemContainer>
        <Heading>
          {title}
          {
            priceModifier && (
              ` (${priceModifier})`
            )
          }
        </Heading>
        {
          nutrition && (
            <NutritionContainer>
              {
                nutrition.map((nutrition, key) => <NutritionItem key={key} value={nutrition.value} unit={nutrition.unit} />)
              }
            </NutritionContainer>
          )
        }
      </ColumnItemContainer>
    );
  }
}

export default ColumnItem;
