import React, { Component } from 'react'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

const NutritionItemContainer = styled.span`
  font-family: "effra_mediumregular";
  font-size: 0.8em;
  line-height: 1.6;
  margin-right: 1em;
`

const Value = styled.span`
  color: ${colors.foreground};
`

const Unit = styled.span`
  color: ${colors.typeLight};
`

class NutritionItem extends Component {
  render() {
    const {
      value,
      unit
    } = this.props;

    return(
      <NutritionItemContainer>
        <Value>{value}&nbsp;</Value>
        <Unit>{unit}</Unit>
      </NutritionItemContainer>
    );
  }
}

export default NutritionItem;
