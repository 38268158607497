import React, { Component } from 'react'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

const CharacterSphereContainer = styled.div`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.foreground};
  color: ${colors.background};
  font-family: "effrabold";
  font-size: 0.875em;
  margin-right: 1em;
  aspect-ratio: 1 / 1;
  -webkit-aspect-ratio: 1 / 1;
  padding: 14px;
  width: 0;

  span {
    line-height: 0;
  }
`

class CharacterSphere extends Component {
  render() {
    const { character } = this.props;

    return(
      <CharacterSphereContainer>
        <span>{character}</span>
      </CharacterSphereContainer>
    );
  }
}

export default CharacterSphere;
