export const milkTeas = [

  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "original milk tea",
  //   "price": "$5.5",
  //   "description": "Assam tea and your choice of dairy sweetened with our house made simple syrup and topped with tapioca bubbles & caramel drizzle.",
  // },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "chocolate milk",
    "price": "$6",
    "description": "Chocolate mixed with honey shaken with Assam tea & your choice of dairy. Garnished with Oreo cookie pieces, chocolate syrup, and topped with tapioca bubbles.",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "brown sugar",
    "price": "$6",
    "description": "Dark brown sugar shaken with Assam and your choice of dairy. Garnished with dark brown sugar, and topped with tapioca bubbles.",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "thai tea",
    "price": "$6.5",
    "description": "Star anise, cinnamon and vanilla flavors sweetened with honey, Assam tea, and your choice of dairy. Topped with tapioca bubbles.",
  },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "thin mint",
  //   "price": "$5.5",
  //   "description": "Sweet chocolate flavor paired with mint, Assam tea, and your choice of dairy. Garnished with white chocolate and topped with tapioca bubbles.",
  // },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "green melon",
    "price": "$6",
    "description": "Sweet green honeydew paired with honey, green tea, and your choice of dairy. Topped with strawberry bubbles.",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "purple taro",
    "price": "$6",
    "description": "Japanese purple yam sweetened with honey, green tea, and your choice of dairy. Topped with tapioca bubbles.",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "strawberry cream",
    "price": "$5.5",
    "description": "Sweet strawberry, green tea, and honey combined with your choice of dairy. Topped with tapioca bubbles.",
  },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "sweet red bean",
  //   "price": "$4.5",
  //   "description": "Sweet red bean paired with honey, Assam tea, and your choice of dairy. Topped with tapioca bubbles and crushed Oreos.",
  // },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "custom milk tea",
  //   "price": "$5",
  //   "description": "Pick up to two current flavors! Choose between green, Assam, or Butterfly tea. Made with your choice of sweetener, milk, and bubbles; don’t forget to pick a garnish!",
  // },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "s'mores",
  //   "price": "$5",
  //   "description": "A campfire taste! Chocolate and toasted marshmallow flavors mixed with your choice of dairy. Garnished with chocolate syrup, graham crackers, and mini marshmallows.",
  // },

]
