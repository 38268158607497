import React, { Component } from 'react'
import styled from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
// import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Images
import AppleAppStoreImage from "../../assets/images/apple-app-store-compound.svg"
import GooglePlayImage from "../../assets/images/google-play.svg"

// Styled Components
const AppStoreNavContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  a {
    display: inline-flex;
    margin-right: 1em;

    img {
      height: 2.6em;
    }
  }
`

// AppStoreNav
class AppStoreNav extends Component {
  render() {
    return(
      <AppStoreNavContainer>
        <a href="https://apps.apple.com/us/app/balance-grille-app/id1171411865" title="Download the Balance App on the Apple App Store">
          <img alt='Download the Balance App on the Apple App Store' src={AppleAppStoreImage} />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.loyaltyplant.partner.balancegrill&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <img alt='Get it on Google Play' src={GooglePlayImage} />
        </a>
      </AppStoreNavContainer>
    );
  }
}

export default AppStoreNav;
