import React, { Component } from 'react'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import CharacterSphere from '../../components/CharacterSphere'

const StepTitleContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 2em;
`

const Title = styled.div`
  display: flex;
  color: ${colors.foreground};
  font-family: "effrabold";
  text-transform: uppercase;
  letter-spacing: 0.2em;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.875em;

  span {
    margin-right: 1em;
  }

  &:after {
    content: "";
    height: 1px;
    flex: 1;
    background-color: rgba(255,255,255, 0.1);
  }
`

class StepTitle extends Component {
  render() {
    const { number, title, className } = this.props;

    return(
      <StepTitleContainer className={className}>
        {
          number && (
            <CharacterSphere character={number} />
          )
        }
        <Title className={className}>
          <span>{title}</span>
        </Title>
      </StepTitleContainer>
    );
  }
}

export default StepTitle;
