import React from 'react'

const SVGSprite = () => (
  <svg className="hide">

	<symbol id="halal" viewBox="0 0 24 24">
    <path d="M12,0C5.37,0,0,5.37,0,12s5.37,12,12,12,12-5.37,12-12S18.63,0,12,0ZM8.21,8.38s3.42,1.07,3,4.82c0,0-.42-1.67-1.19-2.21-.77-.54-1.74-.4-1.81-1.41-.07-1,0-1.2,0-1.2h0ZM4.79,16.48c-1.67-.07-2.37-1-2.44-1.94-.07-.94.42-1.81,1.05-2.54,0,0-1.12,2.14-.07,2.81,1.05.67,2.86.53,3.77-.27.91-.8-.49-7.43-.49-7.43,0,0,.49-.74.63-1.07,0,0,.35,1.2.83,1.47,0,0-.35.54-.35,1.47s.49,4.55.21,5.22c-.28.67-1.47,2.34-3.14,2.27ZM19.23,14.67c-1.47.54-3.49,1.21-4.54.74-1.05-.47-1.4-1.47-1.4-5.49,0,0-.49,2.81-1.19,4.15-.7,1.34-2.65,2.61-5.37,2.21,0,0,4.88-1.07,5.79-5.35.91-4.28.84-3.34.84-3.34,0,0,.42-.2.49,1.87.07,2.07,0,4.75,1.26,4.88,1.25.14,2.79.07,3.63-.67,0,0-1.81-1.01-2.72-.54-.79.41-.95.72-.97.79.05-.16.67-2.06,1.81-2.19,1.19-.13,2.37.87,3.21,1.21.84.33,2.02.27,2.02.27l-.84,1.27s-1.09-.14-2.02.2h0Z"/>
  </symbol>

    <symbol id="gluten-free" viewBox="0 0 68.8 68.8">
      <path d="M26.2,37.1V33h8.2v9c-1.2,1-2.6,1.8-4.1,2.2c-1.6,0.5-3.1,0.7-4.7,0.7c-2.2,0-4-0.4-5.6-1.3 c-1.6-0.9-2.8-2.1-3.6-3.6c-0.8-1.6-1.2-3.4-1.2-5.6c0-2,0.4-3.9,1.3-5.4c0.9-1.6,2.1-2.8,3.7-3.7c1.6-0.9,3.4-1.3,5.4-1.3 c1.7,0,3.2,0.3,4.7,0.8c1.5,0.6,2.7,1.5,3.6,2.7l-3.5,3.5c-0.6-0.6-1.3-1.1-2-1.5C27.8,29,27,28.8,26,28.8c-1.1,0-2.1,0.3-2.9,0.8 c-0.8,0.5-1.4,1.2-1.8,2c-0.4,0.9-0.6,1.8-0.6,2.9c0,1.7,0.5,3.1,1.5,4.1c1,1,2.3,1.5,3.9,1.5c0.7,0,1.3-0.1,1.9-0.3 c0.6-0.2,1.2-0.4,1.7-0.8v-1.9H26.2z M39.7,44.4v-20h14v4.6H45V33h6.7v4.4H45v7.1H39.7z M34.4,68.8C15.4,68.8,0,53.4,0,34.4 S15.4,0,34.4,0s34.4,15.4,34.4,34.4S53.4,68.8,34.4,68.8z M34.4,6C18.7,6,6,18.7,6,34.4c0,15.7,12.7,28.4,28.4,28.4 c15.7,0,28.4-12.7,28.4-28.4C62.8,18.7,50.1,6,34.4,6z" />
    </symbol>

    <symbol id="vegetarian" viewBox="0 0 156 156">
      <path d="M53.9,143.9c-11.1,0-22.2-1.8-32.8-5.4c-1.8-0.6-3.2-2-3.8-3.8c-8.8-25.8-6.9-53.5,5.1-77.9C34.6,32.4,55.5,14.1,81.3,5.4 c21.1-7.2,44.4-7.2,65.5,0c1.8,0.6,3.2,2,3.8,3.8c18.1,53.3-10.6,111.4-63.9,129.4C76.1,142.1,65,143.9,53.9,143.9z M27.9,128.1 c17.9,5.4,37.3,5.1,55-0.9c46.1-15.6,71.3-65.1,57.3-111.4c-17.9-5.4-37.3-5.1-55,0.9C62.4,24.5,44,40.6,33.3,62.2 C23.1,82.8,21.2,106.1,27.9,128.1z" />
      <path d="M6,156c-1.5,0-3.1-0.6-4.2-1.8c-2.3-2.3-2.3-6.1,0-8.5l102-102c2.3-2.3,6.1-2.3,8.5,0c2.3,2.3,2.3,6.1,0,8.5l-102,102 C9.1,155.4,7.5,156,6,156z" />
    </symbol>

    <symbol id="vegan" viewBox="0 0 300.6 156">
      <path d="M198.5,143.9c-11.1,0-22.2-1.8-32.8-5.4c-1.8-0.6-3.2-2-3.8-3.8c-8.8-25.8-6.9-53.5,5.1-77.9c12.1-24.4,32.9-42.7,58.8-51.5 c21.1-7.2,44.4-7.2,65.5,0c1.8,0.6,3.2,2,3.8,3.8c18.1,53.3-10.6,111.4-63.9,129.4C220.7,142.1,209.6,143.9,198.5,143.9z M172.4,128.1c17.9,5.4,37.3,5.1,55-0.9c46.1-15.6,71.3-65.1,57.3-111.4c-17.9-5.4-37.3-5.1-55,0.9c-22.8,7.7-41.2,23.9-51.8,45.4 C167.7,82.8,165.8,106.1,172.4,128.1z"/>
      <path d="M150.6,156c-1.5,0-3.1-0.6-4.2-1.8c-2.3-2.3-2.3-6.1,0-8.5l102-102c2.3-2.3,6.1-2.3,8.5,0c2.3,2.3,2.3,6.1,0,8.5l-102,102 C153.6,155.4,152.1,156,150.6,156z"/>
      <path d="M69.3,138.6C16,120.5-12.7,62.4,5.4,9.1C6,7.4,7.4,6,9.2,5.4c21.1-7.2,44.4-7.2,65.5,0c25.8,8.8,46.7,27,58.8,51.5 c12.1,24.4,13.9,52.1,5.1,77.9c-0.6,1.8-2,3.2-3.8,3.8c-10.6,3.6-21.7,5.4-32.8,5.4C91,143.9,79.9,142.1,69.3,138.6z M122.7,62.2 c-10.6-21.6-29.1-37.7-51.8-45.4c-17.7-6-37.1-6.3-55-0.9c-14,46.2,11.2,95.7,57.3,111.4c17.7,6,37.1,6.3,55,0.9 C134.8,106.1,132.9,82.8,122.7,62.2z"/>
      <path d="M145.8,154.2l-102-102c-2.3-2.3-2.3-6.1,0-8.5c2.3-2.3,6.1-2.3,8.5,0l102,102c2.3,2.3,2.3,6.1,0,8.5 c-1.2,1.2-2.7,1.8-4.2,1.8S146.9,155.4,145.8,154.2z"/>
    </symbol>

    <symbol id="circle-arrow-left" viewBox="0 0 42 42">
      <path d="M0,21c0,11.6,9.4,21,21,21s21-9.4,21-21S32.6,0,21,0S0,9.4,0,21z M2,21C2,10.5,10.5,2,21,2s19,8.5,19,19 s-8.5,19-19,19S2,31.5,2,21z M28.8,20H13.2c-0.6,0-1,0.4-1,1s0.4,1,1,1h15.6c0.6,0,1-0.4,1-1S29.3,20,28.8,20z M21.7,13.9 c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-7.8,7.8c-0.4,0.4-0.4,1,0,1.4l7.8,7.8c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L14.6,21 L21.7,13.9z" />
    </symbol>

    <symbol id="arrow-left" viewBox="0 0 17.6 17.6">
      <path d="M16.6,7.8H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h15.6c0.6,0,1-0.4,1-1S17.1,7.8,16.6,7.8z M9.5,1.7c0.4-0.4,0.4-1,0-1.4 c-0.4-0.4-1-0.4-1.4,0L0.3,8.1c-0.4,0.4-0.4,1,0,1.4l7.8,7.8c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L2.4,8.8L9.5,1.7z" />
    </symbol>

    <symbol id="triangle-down" viewBox="0 0 20 20">
      <path d="M5,6h10l-5,9L5,6z"/>
    </symbol>

    <symbol id="balance-lifestyle-icon" viewBox="0 0 216 216">
      <path d="M209.9,0c4.5,0,7.5,4.8,5.5,8.8L113.5,212.6c-2.1,4.1-7.6,4.5-10.3,1c-0.2-0.3-0.3-0.7-0.5-1 C79.2,165.7,69.3,33.3,143.4,0H209.9 M134.7,0H6.1C1.6,0-1.4,4.8,0.7,8.8l53.5,106.9C65.4,30.3,116.4,6,134.7,0" />
    </symbol>

    <symbol id="balance-classic-logo" viewBox="0 0 490 142">
      <path d="M0,136.9V0h8.7v80.8c3.6-4.5,7.7-7.8,12.3-10.1c4.5-2.2,9.5-3.3,14.9-3.3c9.6,0,17.8,3.5,24.5,10.4
      	c6.8,7,10.2,15.4,10.2,25.3c0,9.8-3.4,18.2-10.3,25.1c-6.8,6.9-15.1,10.4-24.7,10.4c-5.5,0-10.5-1.2-15-3.5
      	c-4.5-2.4-8.4-5.9-11.9-10.6V137H0V136.9z M34.9,130.2c4.8,0,9.3-1.2,13.4-3.6s7.4-5.7,9.8-10.1c2.4-4.3,3.6-8.9,3.6-13.7
      	c0-4.8-1.2-9.4-3.7-13.8c-2.4-4.4-5.7-7.8-9.8-10.2c-4.1-2.4-8.5-3.6-13.2-3.6c-4.8,0-9.3,1.2-13.6,3.6s-7.6,5.7-9.9,9.8
      	c-2.3,4.1-3.4,8.7-3.4,13.9c0,7.8,2.6,14.4,7.7,19.7C21,127.6,27.3,130.2,34.9,130.2z M147.5,69.1v67.7h-8.6v-11.6
      	c-3.7,4.4-7.8,7.8-12.3,10s-9.5,3.4-14.9,3.4c-9.6,0-17.8-3.5-24.6-10.4c-6.8-7-10.2-15.4-10.2-25.4c0-9.8,3.4-18.1,10.3-25
      	c6.8-6.9,15.1-10.4,24.7-10.4c5.6,0,10.6,1.2,15.1,3.5c4.5,2.4,8.5,5.9,11.9,10.6V69.1H147.5z M112.7,75.8c-4.9,0-9.3,1.2-13.4,3.6
      	s-7.4,5.7-9.8,10s-3.6,8.9-3.6,13.7c0,4.8,1.2,9.3,3.7,13.7c2.4,4.4,5.7,7.7,9.9,10.2c4.1,2.4,8.6,3.6,13.3,3.6
      	c4.8,0,9.3-1.2,13.6-3.6c4.3-2.4,7.6-5.7,9.9-9.8c2.3-4.1,3.5-8.7,3.5-13.9c0-7.8-2.6-14.4-7.7-19.7
      	C126.6,78.4,120.2,75.8,112.7,75.8z M257.2,69.1v67.7h-8.6v-11.6c-3.7,4.4-7.8,7.8-12.3,10s-9.5,3.4-14.9,3.4
      	c-9.6,0-17.8-3.5-24.6-10.4c-6.8-7-10.2-15.4-10.2-25.4c0-9.8,3.4-18.1,10.3-25c6.8-6.9,15.1-10.4,24.7-10.4
      	c5.6,0,10.6,1.2,15.1,3.5c4.5,2.4,8.5,5.9,11.9,10.6V69.1H257.2z M222.3,75.8c-4.9,0-9.3,1.2-13.4,3.6c-4.1,2.4-7.4,5.7-9.8,10
      	s-3.6,8.9-3.6,13.7c0,4.8,1.2,9.3,3.7,13.7c2.4,4.4,5.7,7.7,9.9,10.2c4.1,2.4,8.6,3.6,13.3,3.6c4.8,0,9.3-1.2,13.6-3.6
      	s7.6-5.7,9.9-9.8c2.3-4.1,3.5-8.7,3.5-13.9c0-7.8-2.6-14.4-7.7-19.7C236.2,78.4,229.9,75.8,222.3,75.8z M270.4,69.1h8.7v12.1
      	c3.5-4.6,7.3-8.1,11.6-10.4c4.2-2.3,8.8-3.5,13.8-3.5c5.1,0,9.6,1.3,13.5,3.9s6.8,6,8.7,10.4s2.8,11.1,2.8,20.4v34.9h-8.7v-32.3
      	c0-7.8-0.3-13-1-15.6c-1-4.5-2.9-7.9-5.8-10.1c-2.9-2.3-6.6-3.4-11.2-3.4c-5.3,0-10,1.7-14.2,5.2s-6.9,7.8-8.2,12.9
      	c-0.8,3.4-1.2,9.5-1.2,18.4v24.8h-8.7L270.4,69.1L270.4,69.1z M407.5,83.2l-6.9,4.3c-6-7.9-14.1-11.9-24.4-11.9
      	c-8.2,0-15.1,2.7-20.6,8s-8.2,11.8-8.2,19.3c0,4.9,1.3,9.6,3.8,13.9c2.5,4.4,5.9,7.7,10.3,10.1c4.4,2.4,9.3,3.6,14.7,3.6
      	c9.9,0,18.1-4,24.4-11.9l6.9,4.5c-3.2,4.9-7.6,8.7-13,11.3c-5.5,2.7-11.7,4-18.6,4c-10.7,0-19.6-3.4-26.6-10.2
      	c-7.1-6.8-10.6-15.1-10.6-24.8c0-6.6,1.6-12.6,4.9-18.3c3.3-5.6,7.8-10,13.6-13.2c5.8-3.2,12.2-4.7,19.4-4.7c4.5,0,8.8,0.7,13,2.1
      	s7.7,3.2,10.6,5.4C403,77,405.5,79.8,407.5,83.2z M474.9,114.4l7.3,3.9c-2.4,4.7-5.2,8.6-8.3,11.5c-3.2,2.9-6.7,5.1-10.7,6.6
      	c-3.9,1.5-8.4,2.3-13.4,2.3c-11.1,0-19.7-3.6-25.9-10.9c-6.2-7.2-9.3-15.4-9.3-24.6c0-8.6,2.6-16.2,7.9-23
      	c6.7-8.5,15.6-12.8,26.9-12.8c11.5,0,20.8,4.4,27.7,13.1c4.9,6.2,7.4,13.9,7.5,23.2h-60.9c0.2,7.9,2.7,14.3,7.5,19.3
      	c4.9,5,10.9,7.5,18,7.5c3.4,0,6.8-0.6,10.1-1.8c3.3-1.2,6-2.8,8.3-4.8C469.8,122,472.3,118.8,474.9,114.4z M474.9,96.1
      	c-1.2-4.6-2.9-8.4-5.1-11.1c-2.2-2.8-5.2-5-8.8-6.7c-3.7-1.7-7.5-2.6-11.5-2.6c-6.6,0-12.4,2.1-17.1,6.4c-3.5,3.1-6.1,7.8-7.9,14
      	C424.5,96.1,474.9,96.1,474.9,96.1z M226,2c-13.5,5.2-61.2,21.8-61.2,115.6c0,10.7,1.6,20.4,1.6,20.4s5.6-3,4.7-10.5
      	C159.4,35.4,226,2,226,2z M223.8,3.8c-0.9,0.4-2,0.8-3.2,1.4c-0.9,0.4-1.8,0.9-2.8,1.4C217,7,216.2,7.5,215.3,8
      	c-0.7,0.4-1.5,0.9-2.3,1.4c-0.7,0.4-1.4,0.9-2.1,1.4c-0.6,0.4-1.3,0.9-2,1.4c-0.6,0.4-1.2,0.9-1.8,1.4c-0.6,0.4-1.1,0.9-1.7,1.4
      	c-0.5,0.4-1.1,0.9-1.6,1.4c-0.5,0.4-1,0.9-1.6,1.4c-0.5,0.4-1,0.9-1.5,1.4c-0.5,0.4-0.9,0.9-1.4,1.4c-0.4,0.4-0.9,0.9-1.3,1.4
      	c-0.4,0.4-0.9,0.9-1.3,1.4c-0.4,0.4-0.8,0.9-1.2,1.4s-0.8,0.9-1.2,1.4c-0.4,0.4-0.8,0.9-1.1,1.4c-0.4,0.4-0.7,0.9-1.1,1.4
      	c-0.4,0.5-0.7,0.9-1.1,1.4c-0.3,0.5-0.7,0.9-1,1.4c-0.3,0.4-0.6,0.9-1,1.4c-0.3,0.5-0.6,0.9-0.9,1.4c-0.3,0.5-0.6,0.9-0.9,1.4
      	c-0.3,0.5-0.6,0.9-0.9,1.4c-0.3,0.5-0.6,0.9-0.8,1.4c-0.3,0.5-0.5,0.9-0.8,1.4s-0.5,0.9-0.8,1.4s-0.5,0.9-0.8,1.4
      	c-0.2,0.5-0.5,0.9-0.7,1.4s-0.5,0.9-0.7,1.4s-0.5,0.9-0.7,1.4s-0.4,0.9-0.7,1.4c-0.2,0.5-0.4,0.9-0.6,1.4c-0.2,0.5-0.4,0.9-0.6,1.4
      	c-0.2,0.5-0.4,0.9-0.6,1.4c-0.2,0.5-0.4,0.9-0.6,1.4c-0.2,0.5-0.4,0.9-0.6,1.4c-0.2,0.5-0.4,0.9-0.5,1.4c-0.2,0.5-0.3,0.9-0.5,1.4
      	s-0.3,0.9-0.5,1.4s-0.3,0.9-0.5,1.4s-0.3,0.9-0.5,1.4s-0.3,0.9-0.4,1.4c-0.1,0.5-0.3,0.9-0.4,1.4c-0.1,0.5-0.3,0.9-0.4,1.4
      	c-0.1,0.5-0.3,0.9-0.4,1.4c-0.1,0.5-0.3,0.9-0.4,1.4c-0.1,0.5-0.3,0.9-0.4,1.4c-0.1,0.5-0.2,0.9-0.4,1.4c-0.1,0.5-0.2,0.9-0.3,1.4
      	s-0.2,0.9-0.3,1.4s-0.2,0.9-0.3,1.4s-0.2,0.9-0.3,1.4s-0.2,0.9-0.3,1.4s-0.2,0.9-0.3,1.4s-0.2,0.9-0.3,1.4s-0.2,0.9-0.3,1.4
      	s-0.2,0.9-0.2,1.4c-0.1,0.5-0.2,0.9-0.2,1.4c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.1,0.9-0.2,1.4
      	c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.1,0.9-0.2,1.4
      	c0,0.5-0.1,0.9-0.1,1.4s-0.1,0.9-0.1,1.4s-0.1,0.9-0.1,1.4s-0.1,0.9-0.1,1.4s-0.1,0.9-0.1,1.4s-0.1,0.9-0.1,1.4s-0.1,0.9-0.1,1.4
      	s0,0.9-0.1,1.4c0,0.5,0,0.9-0.1,1.4c0,0.5,0,0.9-0.1,1.4c0,0.5,0,0.9,0,1.4s0,0.9,0,1.4s0,0.9,0,1.4s0,0.9,0,1.4s0,0.9,0,1.4
      	s0,0.9,0,1.4s0,0.9,0,1.4c0,0.1,0,0.1,0,0.2c0,0.4,0,0.8,0,1.2c0,0.5,0,0.9,0,1.4s0,0.9,0,1.4s0,0.9,0,1.4s0,0.9,0,1.4
      	s0,0.9,0.1,1.4c0,0.5,0,0.9,0.1,1.4c0,0.5,0.1,0.9,0.1,1.4s0.1,0.9,0.1,1.4s0.1,0.9,0.1,1.4s0.1,0.9,0.1,1.4s0.1,1,0.1,1.4
      	c0.1,0.5,0.1,1,0.1,1.4c0.1,0.9,0.2,1.4,0.2,1.4s0.7-0.4,1.6-1.4c0.3-0.4,0.7-0.8,1.1-1.4c0.3-0.4,0.5-0.9,0.8-1.4
      	c0.2-0.4,0.4-0.9,0.6-1.4c0.1-0.4,0.3-0.9,0.4-1.4c0.1-0.4,0.2-0.9,0.2-1.4c0-0.4,0.1-0.9,0.1-1.4c0-0.3,0-0.5,0-0.8
      	c0-0.2,0-0.4,0-0.6c0-0.4,0-0.9,0-1.4c0-0.4,0-0.9,0-1.4c0-0.4,0-0.9,0-1.4c0-0.4,0-0.9,0-1.4c0-0.4,0-0.9,0-1.4c0-0.4,0-0.9,0-1.4
      	s0-0.9,0-1.4s0-0.9,0-1.4s0-0.9,0-1.4s0-0.9,0-1.4s0-0.9,0-1.4s0-0.9,0-1.4s0-0.9,0-1.4s0-0.9,0-1.4s0-0.9,0-1.4s0-0.9,0-1.4
      	s0-0.9,0.1-1.4c0-0.5,0-0.9,0.1-1.4c0-0.5,0-0.9,0.1-1.4c0-0.5,0.1-0.9,0.1-1.4s0.1-0.9,0.1-1.4s0.1-0.9,0.1-1.4s0.1-0.9,0.1-1.4
      	s0.1-0.9,0.1-1.4s0.1-0.9,0.1-1.4s0.1-0.9,0.1-1.4s0.1-0.9,0.2-1.4c0.1-0.5,0.1-0.9,0.2-1.4c0.1-0.5,0.1-0.9,0.2-1.4
      	c0.1-0.5,0.1-0.9,0.2-1.4c0.1-0.5,0.1-0.9,0.2-1.4c0.1-0.5,0.1-0.9,0.2-1.4c0.1-0.5,0.1-0.9,0.2-1.4c0.1-0.5,0.2-0.9,0.2-1.4
      	c0.1-0.5,0.2-0.9,0.3-1.4s0.2-0.9,0.3-1.4s0.2-0.9,0.3-1.4s0.2-0.9,0.3-1.4s0.2-0.9,0.3-1.4s0.2-0.9,0.3-1.4s0.2-0.9,0.3-1.4
      	s0.2-0.9,0.3-1.4s0.2-0.9,0.4-1.4c0.1-0.5,0.2-0.9,0.4-1.4c0.1-0.5,0.3-0.9,0.4-1.4c0.1-0.5,0.3-0.9,0.4-1.4
      	c0.1-0.5,0.3-0.9,0.4-1.4c0.1-0.5,0.3-0.9,0.4-1.4c0.1-0.5,0.3-0.9,0.5-1.4s0.3-0.9,0.5-1.4s0.3-0.9,0.5-1.4s0.3-0.9,0.5-1.4
      	s0.3-0.9,0.5-1.4s0.4-0.9,0.5-1.4c0.2-0.5,0.4-0.9,0.6-1.4c0.2-0.5,0.4-0.9,0.6-1.4c0.2-0.5,0.4-0.9,0.6-1.4
      	c0.2-0.5,0.4-0.9,0.6-1.4c0.2-0.5,0.4-0.9,0.7-1.4c0.2-0.5,0.5-0.9,0.7-1.4s0.5-0.9,0.7-1.4s0.5-0.9,0.7-1.4s0.5-0.9,0.8-1.4
      	s0.5-0.9,0.8-1.4s0.5-0.9,0.8-1.4s0.6-0.9,0.8-1.4c0.3-0.5,0.6-0.9,0.9-1.4c0.3-0.5,0.6-0.9,0.9-1.4c0.3-0.5,0.6-0.9,0.9-1.4
      	c0.3-0.5,0.7-0.9,1-1.4c0.3-0.5,0.7-0.9,1-1.4s0.7-0.9,1-1.4c0.4-0.5,0.7-0.9,1.1-1.4c0.4-0.5,0.7-0.9,1.1-1.4
      	c0.4-0.5,0.8-0.9,1.2-1.4s0.8-0.9,1.2-1.4s0.8-0.9,1.3-1.4c0.4-0.5,0.9-0.9,1.3-1.4c0.5-0.5,0.9-0.9,1.4-1.4s0.9-0.9,1.4-1.4
      	s1-0.9,1.5-1.4s1-0.9,1.6-1.4c0.5-0.5,1.1-0.9,1.6-1.4c0.6-0.5,1.1-0.9,1.7-1.4c0.6-0.5,1.2-0.9,1.8-1.4s1.3-0.9,1.9-1.4
      	c0.7-0.5,1.3-0.9,2-1.4s1.4-0.9,2.1-1.4c0.7-0.5,1.5-0.9,2.2-1.4c0.8-0.5,1.6-0.9,2.4-1.4c0.8-0.5,1.7-0.9,2.5-1.4
      	C227.9,2.4,226.4,2.8,223.8,3.8z" />
    </symbol>

    <symbol id="balance-new" viewBox="0 0 576 161.2">
      <path d="M261.3,0.2c-53.1,22.2-73.9,90.5-68,156c0.3,2,1.6,3.8,3.5,4.6c0.4,0.2,0.8,0,1-0.4l4.7-6.8c0.7-1.2,1-2.6,0.9-4
      	c-4.1-50,9.2-116.7,58.2-148.8c0.1-0.1,0.2-0.3,0.1-0.4C261.6,0.2,261.4,0.1,261.3,0.2L261.3,0.2z"/>
      <path d="M9.9,159H0.3c-0.2,0-0.3-0.2-0.3-0.4V0.3C0,0.2,0.2,0,0.3,0c0,0,0,0,0,0h9.5c0.2,0,0.3,0.2,0.3,0.3c0,0,0,0,0,0v92.5
      	C18.9,82.3,29.8,77,42.2,77c23.3,0,41,19.4,41,42.1c0,22.5-18,42-41.3,42c-12.9,0-23.6-5.7-31.7-16.8v14.3
      	C10.2,158.8,10.1,159,9.9,159z M72.8,118.8c0-17.3-13.7-32.5-31.6-32.5c-16.4,0-31.7,12.2-31.7,32.2c0,18.5,13.7,32.6,31.7,32.6
      	C59.7,151.1,72.8,135.9,72.8,118.8L72.8,118.8z"/>
      <path d="M164.5,79.1h9.4c0.2,0,0.3,0.2,0.3,0.3c0,0,0,0,0,0v79.2c0,0.2-0.2,0.3-0.3,0.4h-9.4c-0.2,0-0.3-0.2-0.3-0.4v-13.3
      	c-8.7,10.5-19.7,15.6-32.2,15.6c-23.3,0-40.9-19.4-40.9-42.1S109.1,77,132.3,77c12.9,0,23.9,5.6,31.9,16.7V79.4
      	C164.2,79.3,164.3,79.1,164.5,79.1C164.5,79.1,164.5,79.1,164.5,79.1z M101.6,119.1c0,17.3,13.5,32.5,31.4,32.5
      	c16.4,0,31.9-12.3,31.9-32.2c0-18.5-13.8-32.5-31.7-32.5C114.8,86.9,101.6,102,101.6,119.1z"/>
      <path d="M293.8,79.1h9.4c0.2,0,0.3,0.2,0.3,0.3c0,0,0,0,0,0v79.2c0,0.2-0.2,0.3-0.3,0.4h-9.4c-0.2,0-0.3-0.2-0.3-0.4v-13.3
      	c-8.7,10.5-19.7,15.6-32.2,15.6c-23.3,0-40.9-19.4-40.9-42.1S238.4,77,261.5,77c12.9,0,23.9,5.6,31.9,16.7V79.4
      	C293.4,79.3,293.6,79.1,293.8,79.1C293.8,79.1,293.8,79.1,293.8,79.1z M230.9,119.1c0,17.3,13.5,32.5,31.4,32.5
      	c16.4,0,31.9-12.3,31.9-32.2c0-18.5-13.8-32.5-31.7-32.5C244.1,86.9,230.9,102,230.9,119.1z"/>
      <path d="M330.2,93.4c6.4-10.3,17.8-16.5,29.9-16.4c17.4,0,29.6,12,29.6,37.1v44.5c0,0.2-0.2,0.3-0.3,0.4h-9.5
      	c-0.2,0-0.3-0.2-0.3-0.4v-43.1c0-18.8-5.9-29.2-21.4-29.2c-8.6,0-15.6,3.8-21,10.8c-6,7.8-6.9,15.6-6.9,28.3v33.2
      	c0,0.2-0.2,0.3-0.3,0.4h-9.5c-0.2,0-0.3-0.2-0.3-0.4V79.4c0-0.2,0.2-0.3,0.3-0.3l0,0h9.5c0.2,0,0.3,0.2,0.3,0.3c0,0,0,0,0,0
      	L330.2,93.4z"/>
      <path d="M497.7,119.8c0.4,18.3,12.9,31.7,30.1,31.7c16.5,0,24.5-9.3,30.4-18.8c0.1-0.1,0.3-0.2,0.4-0.1l8,4.2
      	c0.2,0.1,0.2,0.3,0.1,0.5c-8.1,15.7-20.6,23.6-37.9,23.6c-26.5,0-41.7-20.3-41.7-41.7c0-21.8,16.1-42.2,41-42.2
      	c22.7,0,41,16.4,41.3,42.8L497.7,119.8z M558.3,110.8c-4.2-16.5-16.1-23.9-29.9-23.9c-14.3,0-25,7.7-29.6,23.9L558.3,110.8z"/>
      <path d="M470.4,135.3c-0.2-0.1-0.4-0.1-0.5,0.1c0,0,0,0,0,0c-5.4,9.9-15.8,16-27.1,15.9c-18,0-31.1-14.3-31.1-31.7
      	c0-17.7,14-32.5,31.1-32.5c11.3,0,21.4,6.6,26.9,16.2c0.1,0.2,0.3,0.2,0.5,0.1c0,0,0,0,0,0l8.4-5.2c0.2-0.1,0.2-0.3,0.1-0.5
      	c-7.4-12.8-21.1-20.7-35.9-20.6c-23.9,0-41.7,19.4-41.7,42.2s17.6,41.8,41.7,41.8c14.7,0.2,28.4-7.5,35.8-20.2
      	c0.1-0.2,0-0.4-0.1-0.5L470.4,135.3z"/>
      <path d="M569.6,159.6c-2.8,0-5.1-2.3-5.1-5.1s2.3-5.1,5.1-5.1c2.8,0,5.1,2.3,5.1,5.1c0,0,0,0,0,0
      	C574.7,157.3,572.4,159.6,569.6,159.6z M569.6,148.1c-3.5,0-6.4,2.9-6.4,6.4c0,3.5,2.9,6.4,6.4,6.4c3.5,0,6.4-2.9,6.4-6.4
      	C576,150.9,573.1,148.1,569.6,148.1L569.6,148.1z"/>
      <path d="M569.8,155h-1.4v-3h1.4c0.8-0.1,1.6,0.5,1.7,1.4c0.1,0.8-0.5,1.6-1.3,1.7C570,155,569.9,155,569.8,155z M569.7,151.2h-2.4
      	v6.7h1.1v-2h1.8l1.1,2h1.1l-1.3-2.3C573.1,154.6,573.1,151.1,569.7,151.2L569.7,151.2z"/>
    </symbol>

    <symbol id="instagram" viewBox="0 0 22 22">
      <path d="M11.1797 5.12793C8.17969 5.12793 5.78906 7.56543 5.78906 10.5186C5.78906 13.5186 8.17969 15.9092 11.1797 15.9092C14.1328 15.9092 16.5703 13.5186 16.5703 10.5186C16.5703 7.56543 14.1328 5.12793 11.1797 5.12793ZM11.1797 14.0342C9.25781 14.0342 7.66406 12.4873 7.66406 10.5186C7.66406 8.59668 9.21094 7.0498 11.1797 7.0498C13.1016 7.0498 14.6484 8.59668 14.6484 10.5186C14.6484 12.4873 13.1016 14.0342 11.1797 14.0342ZM18.0234 4.94043C18.0234 4.2373 17.4609 3.6748 16.7578 3.6748C16.0547 3.6748 15.4922 4.2373 15.4922 4.94043C15.4922 5.64355 16.0547 6.20605 16.7578 6.20605C17.4609 6.20605 18.0234 5.64355 18.0234 4.94043ZM21.5859 6.20605C21.4922 4.51855 21.1172 3.01855 19.8984 1.7998C18.6797 0.581055 17.1797 0.206055 15.4922 0.112305C13.7578 0.0185547 8.55469 0.0185547 6.82031 0.112305C5.13281 0.206055 3.67969 0.581055 2.41406 1.7998C1.19531 3.01855 0.820312 4.51855 0.726562 6.20605C0.632812 7.94043 0.632812 13.1436 0.726562 14.8779C0.820312 16.5654 1.19531 18.0186 2.41406 19.2842C3.67969 20.5029 5.13281 20.8779 6.82031 20.9717C8.55469 21.0654 13.7578 21.0654 15.4922 20.9717C17.1797 20.8779 18.6797 20.5029 19.8984 19.2842C21.1172 18.0186 21.4922 16.5654 21.5859 14.8779C21.6797 13.1436 21.6797 7.94043 21.5859 6.20605ZM19.3359 16.7061C19.0078 17.6436 18.2578 18.3467 17.3672 18.7217C15.9609 19.2842 12.6797 19.1436 11.1797 19.1436C9.63281 19.1436 6.35156 19.2842 4.99219 18.7217C4.05469 18.3467 3.35156 17.6436 2.97656 16.7061C2.41406 15.3467 2.55469 12.0654 2.55469 10.5186C2.55469 9.01855 2.41406 5.7373 2.97656 4.33105C3.35156 3.44043 4.05469 2.7373 4.99219 2.3623C6.35156 1.7998 9.63281 1.94043 11.1797 1.94043C12.6797 1.94043 15.9609 1.7998 17.3672 2.3623C18.2578 2.69043 18.9609 3.44043 19.3359 4.33105C19.8984 5.7373 19.7578 9.01855 19.7578 10.5186C19.7578 12.0654 19.8984 15.3467 19.3359 16.7061Z" />
    </symbol>

    <symbol id="twitter" viewBox="0 0 25 21">
      <path d="M22.1719 5.64355C23.1094 4.94043 23.9531 4.09668 24.6094 3.1123C23.7656 3.4873 22.7812 3.76855 21.7969 3.8623C22.8281 3.25293 23.5781 2.31543 23.9531 1.14355C23.0156 1.70605 21.9375 2.12793 20.8594 2.3623C19.9219 1.37793 18.6562 0.81543 17.25 0.81543C14.5312 0.81543 12.3281 3.01855 12.3281 5.7373C12.3281 6.1123 12.375 6.4873 12.4688 6.8623C8.39062 6.62793 4.73438 4.65918 2.29688 1.70605C1.875 2.40918 1.64062 3.25293 1.64062 4.19043C1.64062 5.87793 2.48438 7.37793 3.84375 8.26855C3.04688 8.22168 2.25 8.03418 1.59375 7.65918V7.70605C1.59375 10.0967 3.28125 12.0654 5.53125 12.5342C5.15625 12.6279 4.6875 12.7217 4.26562 12.7217C3.9375 12.7217 3.65625 12.6748 3.32812 12.6279C3.9375 14.5967 5.76562 16.0029 7.92188 16.0498C6.23438 17.3623 4.125 18.1592 1.82812 18.1592C1.40625 18.1592 1.03125 18.1123 0.65625 18.0654C2.8125 19.4717 5.39062 20.2686 8.20312 20.2686C17.25 20.2686 22.1719 12.8154 22.1719 6.2998C22.1719 6.06543 22.1719 5.87793 22.1719 5.64355Z" />
    </symbol>

    <symbol id="facebook" viewBox="0 0 167.7 167.7">
      <path d="M83.8,0.3C37.5,0.3,0,37.9,0,84.2c0,41.5,30.2,75.9,69.8,82.6v-65.1H49.6V78.2h20.2V61c0-20,12.2-31,30.1-31 c8.6,0,15.9,0.6,18.1,0.9v20.9l-12.4,0c-9.7,0-11.6,4.6-11.6,11.4v14.9h23.2l-3,23.4H94v65.7c41.5-5,73.6-40.3,73.6-83.2 C167.7,37.9,130.1,0.3,83.8,0.3z"/>
    </symbol>

  </svg>
)

export default SVGSprite
