import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

// Data
import { tacos } from "../../data/tacos-local";

// Components
import BackLink from '../../components/BackLink'
import SubHeader from '../../components/SubHeader'
import MenuSection from '../../components/MenuSection'
import MenuItems from '../../components/MenuItems'
import MenuItem from '../../components/MenuItem'
import StepTitle from '../../components/StepTitle'

// Tacos
class Tacos extends Component {
  render() {
    return(
      <>
        <Helmet>
          <title>Tacos - Menu | Balance Pan-Asian Grille</title>
          <meta name="description" content="Yup. Asian tacos! Served on local-made puffed blue corn or steamed flour tortilla. Vegan and gluten-free options." />
        </Helmet>
        <MenuSection>
          <div className="wrap">
            <StepTitle
              title="Toledo"
            />
          </div>
          <SubHeader
            title="tacos"
            description="Choose <strong>yellow corn</strong> or a <strong>steamed flour</strong> tortilla. <strong>Grilled chicken</strong>, <strong>wok-fired steak (+$1.00)</strong>, <strong>tofu</strong>, or <strong>lentils</strong> are available as a protein on any taco. <em>Prices shown below are for a pair (2 tacos). Nutrition listed accounts for tacos including sauce.</em>"
            calloutText="Taco Trio: choose any three tacos for $10"
          />
          <MenuItems>
            {
              tacos.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
          </MenuItems>
        </MenuSection>
        <BackLink url="/menu"/>
      </>
    );
  }
}

export default Tacos;
