export const bowls = [

  // {
  //   "image": "https://balancegrille.s3.amazonaws.com/menu-images/bowls/poke-bowl.webp",
  //   "title": "poke",
  //   "price": "$15",
  //   "flavorTags": [
  //     { "tag": "Salty" },
  //     { "tag": "Savory" },
  //     { "tag": "Spicy 2/5" },
  //   ],
  //   "description": "A simple preparation of sushi grade ahi tuna, seasoned with a Korean-inspired soy-based dressing and sesame seeds served on top of salad greens. Comes with your choice of either YumYum or Wasabi Aioli, as well as an additional 8oz starch option for free.",
  //   "descriptionNotes": "Dairy Free, Contains Gluten (Soy Glaze on the Tuna), Nut Free (But contains Sesame Seeds, can’t be opted out), Shellfish Free, Safe for Pescatarians.",
  //   // "dietary": [
  //   //   { "type": "Vegetarian", "title": "Vegetarian", }
  //   // ],
  //   "nutrition": [
  //     { "value": "Available for a Limited Time Only", "unit": "" },
  //   ]
  // },

  // {
  //   "image": "https://balancegrille.s3.amazonaws.com/menu-images/bowls/five-spice-popper-bowl.webp",
  //   "title": "five spice popper bowl",
  //   "price": "$9",
  //   "flavorTags": [
  //     { "tag": "Herby and Aromatic" },
  //     { "tag": "Savory" },
  //     { "tag": "Sweet" },
  //   ],
  //   "description": "Made with fresh, juicy chicken thigh meat, hand-battered and fried daily. These poppers are coated in our signature Five Spice sauce and served on a bed of red cabbage and garnished with scallions.",
  //   // "dietary": [
  //   //   { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
  //   // ],
  //   // "nutrition": [
  //   //   { "value": "180", "unit": "Calories" },
  //   //   { "value": "8g", "unit": "Protein" },
  //   //   { "value": "33g", "unit": "Total Carbohydrate" },
  //   //   { "value": "3g", "unit": "Total Fat" },
  //   // ]
  // },

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/bowls/green-curry-bowl.webp",
    "title": "green curry",
    "price": "$10",
    "flavorTags": [
      { "tag": "Herbacious" },
      { "tag": "Savory" },
      { "tag": "Spicy 3/5" },
    ],
    "description": "This classic Thai curry bowl is packed with herbs, spices, and heat. It is served with red bell peppers, peapods, seasonal sprouts, crushed peanuts and cilantro.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens or White / Brown Rice." },
    ],
    "nutrition": [
      { "value": "300", "unit": "Calories" },
      { "value": "6g", "unit": "Protein" },
      { "value": "18g", "unit": "Total Carbohydrate" },
      { "value": "24g", "unit": "Total Fat" },
    ]
  },

  // {
  //   "image": "https://balancegrille.s3.amazonaws.com/menu-images/bowls/balance-bowls-filipino-adobowl.png",
  //   "title": "filipino adobowl",
  //   "price": "$10",
  //   "flavorTags": [
  //     { "tag": "Savory" },
  //     { "tag": "Salty" },
  //   ],
  //   "description": "A classic dish from the Philippines. Neither spicy nor sweet, the filipino adobowl pairs a mellow, savory sauce with a generous amount of bay leaves and garlic. Made for anyone sensitive to spicy foods or sugar.",
  //   "dietary": [
  //     { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
  //   ],
  //   "nutrition": [
  //     { "value": "180", "unit": "Calories" },
  //     { "value": "8g", "unit": "Protein" },
  //     { "value": "33g", "unit": "Total Carbohydrate" },
  //     { "value": "3g", "unit": "Total Fat" },
  //   ]
  // },

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/bowls/Bowls-Yumyum.webp",
    "title": "yum yum",
    "price": "$10",
    "flavorTags": [
      { "tag": "Spicy 3/5" },
      { "tag": "Delicately Sweet" },
      { "tag": "Mildly Citrusy" }
    ],
    "description": "A creamy Chinese classic served with tomatoes, red onion, and daikon radish. Garnished with seasonal potatoes and scallions.",
    // "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens or White / Brown Rice." }
    ],
    "nutrition": [
      { "value": "760", "unit": "Calories" },
      { "value": "4g", "unit": "Protein" },
      { "value": "49g", "unit": "Total Carbohydrate" },
      { "value": "56g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/budda.png",
    "title": "budda",
    "price": "$10",
    "flavorTags": [
      { "tag": "Spicy 3/5" },
      { "tag": "Savory" },
      { "tag": "Aromatic" }
    ],
    "description": "Spicy Indian inspired butter sauce served with chickpeas, red onions, tomato, Balance Farms micro cilantro, and seasonal sprouts.",
    "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens or White / Brown Rice." }
    ],
    "nutrition": [
      { "value": "400", "unit": "Calories" },
      { "value": "5g", "unit": "Protein" },
      { "value": "18g", "unit": "Total Carbohydrate" },
      { "value": "36g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/four-star.png",
    "title": "four-star",
    "price": "$10",
    "flavorTags": [
      { "tag": "Garlicky" },
      { "tag": "Tangy" },
      { "tag": "Sweet" }
    ],
    "description": "A tangy, garlicky General Tso’s sauce served with broccoli, carrots, and seasonal sprouts.",
    "descriptionNotes": "",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "150", "unit": "Calories" },
      { "value": "4g", "unit": "Protein" },
      { "value": "30g", "unit": "Total Carbohydrate" },
      { "value": "1g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/wiseman.png",
    "title": "wiseman",
    "price": "$10",
    "flavorTags": [
      { "tag": "Spicy 2/5" },
      { "tag": "Sweet" },
      { "tag": "Savory" }
    ],
    "description": "Customer favorite! A simple szechuan sauce served with broccoli, carrots, corn, seasonal sprouts, and kale.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "230", "unit": "Calories" },
      { "value": "6g", "unit": "Protein" },
      { "value": "51g", "unit": "Total Carbohydrate" },
      { "value": "1.5g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/thai-guy.png",
    "title": "thai guy",
    "price": "$10",
    "flavorTags": [
      { "tag": "Spicy 3/5" },
      { "tag": "Creamy" },
      { "tag": "Nutty" }
    ],
    "description": "Thai-style peanut sauce served with carrots, seasonal hot peppers, seasonal sprouts, crushed peanuts, peapods, and Balance Farms micro cilantro. ",
    "descriptionNotes": "Contains Nuts.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens or White / Brown Rice" }
    ],
    "nutrition": [
      { "value": "370", "unit": "Calories" },
      { "value": "13g", "unit": "Protein" },
      { "value": "47g", "unit": "Total Carbohydrate" },
      { "value": "17g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/teriyaki.png",
    "title": "teriyaki",
    "price": "$10",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Savory" },
      { "tag": "Salty" }
    ],
    "description": "Classic Japanese teriyaki Sauce served with broccoli, carrots, red peppers, daikon radish, and red onions.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "180", "unit": "Calories" },
      { "value": "6g", "unit": "Protein" },
      { "value": "39g", "unit": "Total Carbohydrate" },
      { "value": "0g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/bowls/Korean-Bibimbap.webp",
    "title": "korean bibimbap",
    "price": "$10",
    "flavorTags": [
      { "tag": "Spicy 2/5" },
      { "tag": "Tangy" },
      { "tag": "Sweet" }
    ],
    "description": "A classic Korean gochujang sauce with seasonal potatoes, daikon radish, kimchi pickles, red cabbage and seasonal sprouts. If you aren’t vegan, add a 7-min egg (+$2) for the full experience.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      // { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens or White / Brown Rice." }
    ],
    "nutrition": [
      { "value": "360", "unit": "Calories" },
      { "value": "4g", "unit": "Protein" },
      { "value": "82g", "unit": "Total Carbohydrate" },
      { "value": "3g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/sassy-me.png",
    "title": "sassy me",
    "price": "$10",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Slightly Tart" }
    ],
    "description": "A sweet and seductive sesame sauce with fresh kale, peapods, and seasonal sprouts.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "230", "unit": "Calories" },
      { "value": "9g", "unit": "Protein" },
      { "value": "47g", "unit": "Total Carbohydrate" },
      { "value": "1g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/sweet-n-sour.png",
    "title": "sweet & sour",
    "price": "$10",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Sour" },
      { "tag": "Tart" }
    ],
    "description": "Our one-of-a-kind, signature sweet & sour sauce served with carrots, kale, and red onions.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens or White / Brown Rice." },
    ],
    "nutrition": [
      { "value": "240", "unit": "Calories" },
      { "value": "2g", "unit": "Protein" },
      { "value": "58g", "unit": "Total Carbohydrate" },
      { "value": "0g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/nuts-4-pao.png",
    "title": "nuts 4 pao",
    "price": "$10",
    "flavorTags": [
      { "tag": "Spicy 5/5" },
      { "tag": "Savory" },
      { "tag": "Salty" }
    ],
    "description": "A spicy Kung Pao sauce served simply with scallions, roasted peanuts, and seasonal hot peppers. ",
    "descriptionNotes": "Contains nuts.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "390", "unit": "Calories" },
      { "value": "18g", "unit": "Protein" },
      { "value": "21g", "unit": "Total Carbohydrate" },
      { "value": "29g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/kids-bowl.png",
    "title": "kids bowl",
    "price": "$7",
    "description": "Choose a protein and a starch option (found below), then pair with your choice of the following vegetables and sauce.",
    "additionalDescription": [
      { "title": "Vegetables", "text": "Choose broccoli, carrots, red peppers, corn, kale, or peapods." },
      { "title": "Sauces", "text": "Choose from teriyaki, four-star, sweet & sour, or sassy me." },
    ]
  },

]
