import React, { Component } from 'react'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

const IconContainer = styled.svg`
  margin-right: 0.6em;
  height: 20px;
  width: 20px;

  &, use {
    fill: ${colors.health};
  }

  &.vegan {
    width: 2.3em;
  }
`

class Icon extends Component {
  render() {
    const { type } = this.props;

    return(
      <IconContainer className={type}>
        <use xlinkHref={"#" + type} />
      </IconContainer>
    );
  }
}

export default Icon;
