export const bowlsStarch = [

  {
    "title": "brown rice",
    "nutrition": [
      { "value": "270", "unit": "Calories" },
      { "value": "6g", "unit": "Protein" },
      { "value": "56g", "unit": "Total Carbohydrate" },
      { "value": "2g", "unit": "Total Fat" },
    ]
  },
  {
    "title": "fresh greens",
    "nutrition": [
      { "value": "50", "unit": "Calories" },
      { "value": "3g", "unit": "Protein" },
      { "value": "11g", "unit": "Total Carbohydrate" },
      { "value": "0g", "unit": "Total Fat" },
    ]
  },
  {
    "title": "fried rice",
    "nutrition": [
      { "value": "450", "unit": "Calories" },
      { "value": "8g", "unit": "Protein" },
      { "value": "63g", "unit": "Total Carbohydrate" },
      { "value": "17g", "unit": "Total Fat" },
    ]
  },
  {
    "title": "wheat noodles",
    "nutrition": [
      { "value": "410", "unit": "Calories" },
      { "value": "16g", "unit": "Protein" },
      { "value": "80g", "unit": "Total Carbohydrate" },
      { "value": "6g", "unit": "Total Fat" },
    ]
  },
  {
    "title": "white rice",
    "nutrition": [
      { "value": "360", "unit": "Calories" },
      { "value": "7g", "unit": "Protein" },
      { "value": "80g", "unit": "Total Carbohydrate" },
      { "value": "0.5g", "unit": "Total Fat" },
    ]
  },
  // {
  //   "title": "wok-fired cauli rice",
  //   "priceModifier": "+$2.50",
  //   "nutrition": [
  //     { "value": "110", "unit": "Calories" },
  //     { "value": "4g", "unit": "Protein" },
  //     { "value": "12g", "unit": "Total Carbohydrate" },
  //     { "value": "7g", "unit": "Total Fat" },
  //   ]
  // },

]
