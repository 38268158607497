import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom";

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
// import BackLink from '../../components/BackLink'

// Styled Components
const LogoContainer = styled(Link)`
  display: inline-flex;
  flex-direction: column;
`

const LogoSVG = styled.svg`
  height: 2.6em;
  width: 9em;

  &, use {
    fill: ${colors.foreground};
  }
`

// const LogoSubtitle = styled.div`
//   color: rgba(255,255,255,0.68);
//   font-size: 0.875em;
//   line-height: 1.6;
//   position: relative;
//   text-indent: -1px;
// `

// Logo
class Logo extends Component {
  render() {
    // const { title, description } = this.props;

    return(
      <LogoContainer to="/">
        <LogoSVG>
          <use xlinkHref="#balance-new" />
        </LogoSVG>
        {/* <LogoSubtitle>
          <span>pan-asian grille</span>
        </LogoSubtitle> */}
      </LogoContainer>
    );
  }
}

export default Logo;
