export const frozenTeas = [

  // {
  //   "title": "watermelon lemonade",
  //   "price": "4.5",
  //   "description": "Watermelon, lemonade and green tea blended together to serve you a classic summer taste. Topped with kiwi bubbles!",
  // },
  // {
  //   "title": "tooti frooti",
  //   "price": "4.5",
  //   "description": "Cherry and pineapple flavors blended with green tea, paired so perfectly you’ll be feeling frooti. Topped with kiwi bubbles!",
  // },
  {
    "title": "strawberry",
    "price": "$5.5",
    "description": "Sweet Strawberry, green tea, choice of dairy, and our house-made simple syrup topped with tapioca bubbles!",
  },
  // {
  //   "title": "strawberry fields",
  //   "price": "4.5",
  //   "description": "Strawberry and exotic lychee flavors blended with green tea and topped with blueberry bubbles!",
  // },
  {
    "title": "red bean",
    "price": "$5.5",
    "description": "Sweet Red Bean, Assam tea, your choice of dairy and simple syrup blended to put a fun twist on an asian classic. Topped with tapioca bubbles!",
  },
  {
    "title": "pomberry",
    "price": "$5.5",
    "description": "Tart pomegranate and sweet blueberry flavors blended with Assam tea and topped with pomegranate bubbles!",
  },
  {
    "title": "cocoa",
    "price": "$5.5",
    "description": "Chocolate, Assam tea, your choice of dairy and our house-made syrup blended and topped with tapioca bubbles!",
  },
  {
    "title": "blue crush",
    "price": "$5.5",
    "description": " Blue Raspberry, green tea and lemonade blended together and topped with strawberry bubbles. Will make you feel summer vibes!",
  },
  {
    "title": "blood berry",
    "price": "$5.5",
    "description": "Blood orange and raspberry flavors blended with Assam tea. Topped with passion fruit bubbles!",
  },

]
