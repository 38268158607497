import React, { Component } from 'react'
import styled from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const SocialNavContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  a {
    color: ${colors.foreground};
    display: inline-flex;
    padding-right: 1em;

    svg {
      height: 1.8em;
      width: 1.8em;

      &, use {
        fill: currentColor;
      }
    }

    &:hover {
      color: ${colors.foreground};

      svg {
        &, use {
          fill: currentColor;
        }
      }
    }
  }
`

// SocialNav
class SocialNav extends Component {
  render() {
    // const { number, title } = this.props;

    return(
      <SocialNavContainer>
        <a href="https://www.facebook.com/balancegrille" target="_blank" rel="noopener noreferrer">
          <svg>
            <use xlinkHref="#facebook" />
          </svg>
        </a>
        <a href="https://twitter.com/balancegrille" target="_blank" rel="noopener noreferrer">
          <svg>
            <use xlinkHref="#twitter" />
          </svg>
        </a>
        <a href="https://www.instagram.com/balancegrille/" target="_blank" rel="noopener noreferrer">
          <svg>
            <use xlinkHref="#instagram" />
          </svg>
        </a>
      </SocialNavContainer>
    );
  }
}

export default SocialNav;
