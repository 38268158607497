import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link } from "react-router-dom";

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const NavItemContainer = styled(Link)`
  color: rgba(255,255,255,0);
  font-size: 6em;
  font-family: "fatfrank";
  line-height: 1.1;
  text-fill-color: transparent;
  -webkit-text-stroke: 1.25px ${colors.foreground};

  ${queryBelow("bigPhone", css`
    font-size: 4.3em;
    -webkit-text-stroke: 1px ${colors.foreground};
  `)}

  &:hover {
    filter: none;
    color: ${colors.foreground};
    text-fill-color: ${colors.foreground};
  }
`

// NavItem
class NavItem extends Component {
  render() {
    const { children, url } = this.props;

    return(
      <NavItemContainer to={url}>
        {children}
      </NavItemContainer>
    );
  }
}

export default NavItem;
