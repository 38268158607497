export const bubbleBrewTeas = [

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "mocha",
    "price": "$4.5",
    "description": "Perk up with this cold brew classic pairing of chocolate and coffee. Shaken with your choice of dairy and served over tapioca bubbles with a chocolate garnish.",
  },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "custom brew",
  //   "price": "$5.5",
  //   "description": "Choose up to 2 bubble brew flavors! Pair them with our Maddie & Bella cold brew and your choice of Milk. Served with your choice of bubbles.",
  // },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "cold brew",
  //   "price": "$3.5",
  //   "description": "Our custom Maddie & Bella Cold Brew shaken with your choice of dairy and served over tapioca bubbles.",
  // },
  {
    "title": "blueberry muffin",
    "price": "$4.5",
    "description": "A sweet treat to give your day the kickstart it needs, with hints of fruit and baked goods. Pair with your choice of dairy and finish with tapioca bubbles.",
  },
  {
    "title": "lavender",
    "price": "$4.5",
    "description": "A popular coffee flavor for fans of floral aromas with hints of mint, citrus, and rosemary. Pair with your choice of dairy and finish with tapioca bubbles.",
  },
  {
    "title": "french vanilla",
    "price": "$4.5",
    "description": "A classic coffee flavor that never goes out of style. Our french vanilla bubble brew is great for our vegan customers. Served with your choice of dairy and tapioca bubbles.",
  },
  {
    "title": "salted caramel",
    "price": "$4.5",
    "description": "A rich caramel flavoring combined with a touch of sea salt! Your choice of dairy served with tapioca bubbles and caramel garnish.",
  },
  {
    "title": "tiramisu",
    "price": "$4.5",
    "description": "The delicious Italian dessert in liquid form! A blend of vanilla and cocoa flavors. Served with your choice of dairy, tapioca bubbles and caramel garnish.",
  },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "toffee crunch",
  //   "price": "$5",
  //   "description": "Sweet and buttery flavors paired with our custom cold brew blend and cold foam made from your choice of dairy. Served with tapioca bubbles.",
  // },
  // {
  //   "title": "peppermint mocha",
  //   "price": "$3.5",
  //   "description": "Winter wonderland. Chocolate and frosted mint mixed with our house brewed cold brew and your choice of dairy. Served with tapioca bubbles.",
  // },

]
