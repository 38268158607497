import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const MenuItemsContainer = styled.div`
  &.not(.columns) {
    ${queryBelow("bigPhone", css`
      max-width: 100%;
      width: 100%;
    `)}
  }

  &.columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.8em 0;

    ${queryBelow("bigPhone", css`
      flex-direction: column;
    `)}

    > div {
      padding: 1em 0;
      width: calc(50% - 2em);

      ${queryBelow("bigPhone", css`
        width: 100%;
      `)}
    }
  }
`

// MenuItems
class MenuItems extends Component {
  render() {
    const { children, className } = this.props;

    return(
      <MenuItemsContainer className={`${className} wrap`}>
        {children}
      </MenuItemsContainer>
    );
  }
}

export default MenuItems;
