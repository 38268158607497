import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
import MenuItemPhoto from "../MenuItemPhoto"
import MenuItemTitle from "../MenuItemTitle"
import FlavorTag from "../FlavorTag"
import DietaryNotice from "../DietaryNotice"
import NutritionItem from "../NutritionItem"
import AdditionalDescription from "../AdditionalDescription"

// Styled Components
const MenuItemContainer = styled.div`
  align-items: flex-start;
  color: ${colors.foreground};
  display: flex;
  width: 100%;
  padding: 2.8em 0;
  margin-bottom: 2px;

  ${queryBelow("bigPhone", css`
    background-color: rgba(255,255,255,0);
    flex-direction: column;
  `)}
`

const MenuItemContent = styled.div`
  position: relative;
  width: calc(100% - (100px - 2rem));

  &.no-photo {
    width: 100%;
  }

  ${queryBelow("bigPhone", css`
    color: white;
    width: 100%;
  `)}

  p {
    color: ${colors.typeLight};
  }
`

const MenuItemRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

class MenuItem extends Component {
  render() {
    const {
      description,
      additionalDescription,
      dietary,
      flavorTags,
      image,
      nutrition,
      price,
      title,
    } = this.props;

    return(
      <MenuItemContainer>
        {
          image && (
            <MenuItemPhoto image={image} />
          )
        }
        <MenuItemContent className={`${image ? "standard" : "no-photo"}`}>
          <MenuItemTitle
            title={title}
            price={price}
          />
          {
            flavorTags && (
              <MenuItemRow>
                {
                  flavorTags.map((flavorTag, key) => <FlavorTag key={key} flavorTagText={flavorTag.tag} />)
                }
              </MenuItemRow>
            )
          }
          {
            description && (
              <p>{description}</p>
            )
          }
          {
            additionalDescription && (
              additionalDescription.map((additionalDescription, key) => <AdditionalDescription key={key} title={additionalDescription.title} text={additionalDescription.text} />)
            )
          }
          {
            dietary && (
              <MenuItemRow>
                {
                  dietary.map((dietary, key) => <DietaryNotice key={key} type={dietary.type} title={dietary.title} subtitle={dietary.subtitle} />)
                }
              </MenuItemRow>
            )
          }
          {
            nutrition && (
              <MenuItemRow>
                {
                  nutrition.map((nutrition, key) => <NutritionItem key={key} value={nutrition.value} unit={nutrition.unit} />)
                }
              </MenuItemRow>
            )
          }
        </MenuItemContent>
      </MenuItemContainer>
    );
  }
}

export default MenuItem;
