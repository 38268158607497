import React, { Component } from 'react'
// import styled from 'styled-components'

// Utilities
// import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import Title from "../Title"

// AdditionalDescription
class AdditionalDescription extends Component {
  render() {
    const {
      title,
      text,
    } = this.props;

    return(
      <div>
        {
          title && (
            <Title text={title} />
          )
        }
        {
          text && (
            <p>{text}</p>
          )
        }
      </div>
    );
  }
}

export default AdditionalDescription;
