import React, { Component } from 'react'
import styled, { css } from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
// import BackLink from '../../components/BackLink'
import SimpleCallout from '../../components/SimpleCallout'

// Styled Components
const SubHeaderContainer = styled.div`
  align-items: flex-start;
  color: ${colors.typeLight};
  display: flex;
  flex-direction: column;

  h1 {
    -webkit-text-stroke: 1.5px ${colors.foreground};
    color: rgba(255,255,255, 0);
    font-size: 6em;
    text-fill-color: rgba(255,255,255,0);

    ${queryBelow("bigPhone", css`
      font-size: 4.3em;
      -webkit-text-stroke: 1px white;
    `)}
  }

  p {
    margin-bottom: 2em;
  }
`

// SubHeader
class SubHeader extends Component {
  render() {
    const {
      title,
      description,
      calloutText,
    } = this.props;

    return(
      <SubHeaderContainer className="wrap">
        <h1>{title}</h1>
        {
          description && (
            <p dangerouslySetInnerHTML={{__html: this.props.description}}></p>
          )
        }
        {
          calloutText && (
            <SimpleCallout
              calloutText={calloutText}
            />
          )
        }
      </SubHeaderContainer>
    );
  }
}

export default SubHeader;
