import React, { Component } from 'react'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"

const IngredientContainer = styled.p`
  position: relative;
  margin-bottom: 0.6rem;
  line-height: 1.3;
`

const BalanceFarmsIndicator = styled.svg`
  height: 0.8em;
  width: 0.8em;

  &, use {
    fill: ${colors.health};
  }
`

class Ingredient extends Component {
  render() {
    const {
      className,
      title,
      source
    } = this.props;

    return(
      <IngredientContainer className={className}>
        {title}
        {
          source === "Balance Farms" && (
            <BalanceFarmsIndicator>
              <use xlinkHref="#triangle-down" />
            </BalanceFarmsIndicator>
          )
        }
      </IngredientContainer>
    );
  }
}

export default Ingredient;
