import React, { Component } from 'react'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

const TitleContainer = styled.div`
  color: ${colors.foreground};
  display: inline-block;
  font-family: 'effrabold';
  font-size: 0.8em;
  letter-spacing: 0.16em;
  margin-bottom: 1em;
  text-transform: uppercase;
  width: 100%;
`

class Title extends Component {
  render() {
    const { className, text } = this.props;

    return(
      <TitleContainer className={className}>
        {text}
      </TitleContainer>
    );
  }
}

export default Title;
