import React, { Component } from 'react'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import Icon from "../Icon"

const DietaryNoticeContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 1em 1em 0;

  &.subtitled {
    align-items: flex-start;
  }
`

// Serves to contain text to preserve flex
const TextContainer = styled.div``

const Title = styled.div`
  font-family: 'effrabold';
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 0.8em;
`

const Subtitle = styled.div`
  margin-top: 0.4em;
  font-family: 'effra_mediumregular';
  font-size: 0.8em;
  color: ${colors.typeLight};

  span {
    color: #000000;
  }
`

class DietaryNotice extends Component {
  render() {
    const {
      type,
      title,
      subtitle,
    } = this.props;

    return(
      <DietaryNoticeContainer className={`${ subtitle ? "subtitled" : ""}`}>
        <Icon type={type} />
        <TextContainer>
          <Title>{title}</Title>
          {
            subtitle && (
              <Subtitle>{subtitle}</Subtitle>
            )
          }
        </TextContainer>
      </DietaryNoticeContainer>
    );
  }
}

export default DietaryNotice;
