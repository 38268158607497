import React, { Component } from 'react'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

const FlavorTagContainer = styled.div`
  border: 2px solid ${colors.typeLight};
  display: inline-block;
  font-family: "effrabold";
  font-size: 0.75em;
  letter-spacing: 0.1875em;
  line-height: 1.14;
  margin: 0 0.6em 0.6em 0;
  padding-bottom: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  text-align: center;
  text-transform: uppercase;
`

class FlavorTag extends Component {
  render() {
    const { flavorTagText } = this.props;

    return(
      <FlavorTagContainer>
        {flavorTagText}
      </FlavorTagContainer>
    );
  }
}

export default FlavorTag;
