import React, { Component } from 'react'

// MenuSection
class MenuSection extends Component {
  render() {
    const { className, children } = this.props;

    return(
      <section className={className}>
        {children}
      </section>
    );
  }
}

export default MenuSection;